import { useContext, useEffect, useState } from "react";
import TrmApi from "trm-api";
import Logo from "../logo_joyeria_inter.svg";
import { ContextReload } from "../App";

export default function Trm(props) {
  const params = new URLSearchParams(window.location.search);
  const [trmToday, setTrmToday] = useState(0);
  const [trmUnicambios, setTrmUnicambios] = useState(0);

  var context = useContext(ContextReload);
  useEffect(() => {
    if (context.reload) {
      context.reloadChange();
      setTrmToday(0);
      setTrmUnicambios(0);
    }
  }, [context]);

  const meses = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  const dateObject = new Date();
  const date = {
    day: dateObject.getDate(),
    monthNumber: dateObject.getMonth() + 1,
    month: meses[dateObject.getMonth()],
    year: dateObject.getFullYear(),
  };
  const dateFull = `${date.year}-${date.monthNumber}-${date.day}`;
  const trmUrl = "https://interapitrm.mdigital-2f5.workers.dev/?date=today";
  //const trmUrl = "https://api-trm-inter.onrender.com/api/?date=today";

  if (!trmToday) {
    /*const trmApi = new TrmApi("DhcOn91ZYWyGBxlba2NJXVwhF");
        trmApi.date(dateFull).then(data => {
            setTrmToday(data.valor)
        });*/

    fetch(trmUrl, { cache: "no-store" })
      .then((res) => res.json())
      .then(({ data }) => {
        setTrmToday(data.value);
      });
  }

  if (!params.has("vista") || trmToday) {
    switch (params.get("trm")) {
      case "real":
        return (
          <div className="trm_container box_shadow">
            <div className="trm_container_logo">
              <img src={Logo} alt="Logo"></img>
            </div>
            <div className="trm_container_info">
              <h1>
                Tasa Representativa del Mercado para Dolar Americano (TRM)
              </h1>
              <h2>
                *TRM del día {`${date.day} de ${date.month} de ${date.year}`}.
              </h2>
            </div>
            <div className="trm_container_trm">
              <span className="trm">
                ${getConvertedTrm(trmToday)[0]}
                {
                  <span className="trm_sub">
                    .{getConvertedTrm(trmToday)[1]}
                  </span>
                }
                <span className="currency">
                  COP<span className="trm_sub">/USD</span>
                </span>
              </span>
            </div>
          </div>
        );

      case "cambio":
        return (
          <div
            className="trm_container box_shadow"
            style={{ background: "#fff", color: "#163029", fontWeight: "400" }}
          >
            <div className="trm_container_logo">
              <img src={Logo} alt="Logo"></img>
            </div>
            <div className="trm_container_info">
              <h1>Tasa de cambio para Dolar Americano (USD)</h1>
              <h2>
                *Precio de cambio de divisa para el{" "}
                {`${date.day} de ${date.month} de ${date.year}`}.
              </h2>
            </div>
            <div className="trm_container_trm">
              <span className="trm">
                ${getUnicambios("USD", trmUnicambios)}
                <span className="currency">
                  COP<span className="trm_sub">/USD</span>
                </span>
              </span>
            </div>
          </div>
        );

      case "0":
        break;

      default:
        return (
          <div className="trm_container box_shadow">
            <div className="trm_container_logo">
              <img src={Logo} alt="Logo"></img>
            </div>
            <div className="trm_container_info">
              <h1>Tasa de conversión para Dolar Americano</h1>
              <h2>
                *Las cotizaciones calculadas en dólares tienen validez sólo por
                el día de hoy {`${date.day} de ${date.month} de ${date.year}`}.
              </h2>
            </div>
            <div className="trm_container_trm">
              <span className="trm">
                ${getConvertedTrm(Math.ceil(Math.round(trmToday) / 5) * 5)[0]}
                {/*<span className="trm_sub">.{getConvertedTrm(Math.ceil(Math.round(trmToday) / 5) * 5)[1]}</span>*/}
                <span className="currency">
                  COP<span className="trm_sub">/USD</span>
                </span>
              </span>
            </div>
          </div>
        );
    }
  }

  function getUnicambios(divisa, trmUnicambios) {
    if (!trmUnicambios) {
      fetch("https://unicambios.com.co/wp-json/wp/v2/pages/5423", {
        mode: "no-cors",
      })
        .then((result) => result.json())
        .then((data) => {
          let div = document.createElement("div");
          div.innerHTML = data.content.rendered;

          let trm = div
            .querySelector(`[data-original-value*="${divisa}"]`)
            .nextElementSibling.textContent.trim();
          trm = trm.replace(".", "").replace(",", "");

          setTrmUnicambios(new Intl.NumberFormat("en-US").format(trm - 50));
        });
    }

    return trmUnicambios;
  }

  function getConvertedTrm(expresion) {
    let trm =
      expresion; /**Revisar si se quiere redondear Ahora: 5 pesos, ceil(roundUp) round(default)*/
    trm = new Intl.NumberFormat("en-US").format(trm).split(".");
    if (trm.length < 2) {
      trm.push("00");
    }

    return trm;
  }
}
